_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "{0}-\u0b95\u0bcd\u0b95\u0bbe\u0ba9 \u0baa\u0bca\u0bb0\u0bc1\u0ba8\u0bcd\u0ba4\u0bc1\u0b95\u0bbf\u0ba9\u0bcd\u0bb1 \u0bae\u0ba4\u0bbf\u0baa\u0bcd\u0baa\u0bc8 \u0bb5\u0bb4\u0b99\u0bcd\u0b95\u0bb5\u0bc1\u0bae\u0bcd",
	"crm.label.field.plural":"fields",//no i18n
	"crm.label.in.minutes"	: '{0}( நிமிடங்களில் )',//no i18n
	"crm.security.roles.list" : 'பங்குகள்' /*ரோல்கள்*/,//no i18n
	"crm.security.roles.lookup.info" : "பட்டியலில் இருந்து ஏதேனும் ஒரு பங்கினைத் தேர்ந்தெடு",//no i18n
	"crm.territory.addterritory" : "மண்டலத்தினைச் சேர்"/*"பிரதேசத்தைச் சேர்"*/,//no i18n
	"crm.title.edit.territory" : "மண்டலத்தினைத் திருத்து" /*பிரதேசத்தினைத் திருத்து*/,//no i18n
	"crm.territory.title.assign.territories" : "மண்டலத்தினை ஒதுக்கு" /*பிரதேசத்தை ஒதுக்கு*/,//no i18n
	"crm.label.context.help" : "உதவி",//no i18n
	"crm.label.from" : "இதில் இருந்து",//no i18n
	"crm.label.to" : "இது வரை",//no i18n
	"workflow.option.webhookFailure.fromDate" : "ஆரம்ப தேதி",//no i18n
	"workflow.option.webhookFailure.toDate" : "இறுதி தேதி",//no i18n
	"crm.custom.field.less.than.equalto" : "{0} ஆனது {1} ஐ விட குறைவாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்",//no i18n
	"crm.template.listview.search.no.results" : "முடிவுகள் இல்லை",//no i18n
	"crm.wf.usage.date.criteria.error.msg" : "தொடக்க தேதி முடிவு தேதியை விட அதிகமாக இருக்கக் கூடாது",//no i18n
	"crm.label.tag.new" : "புதிய குறிச்சொல்",//no i18n
	"crm.label.enter.tag" : "குறிச்சொற்களை உள்ளிடு",//no i18n
	"Administrator" : "நிர்வாகி",//no i18n
	"Standard" : "தரநிலை நிர்வாகி",//no i18n
	"crux.comboBox.max.limit" : "உங்களால் {0} {1} ஐ விட அதிகமாக தேர்வு செய்ய முடியாது.",//no i18n
	"crm.button.add" : "சேர்",//no i18n
	"crm.label.users" : "பயனர்கள்",//no i18n
	"crm.workflow.alert.roles" : "பங்குகள்", /*உறுப்புகள்*///no i18n
	"crm.security.groups" : "குழுக்கள்",//no i18n
	"crm.label.available" : "அணுகக்கூடிய பயனர்கள்",//no i18n
	"crm.label.assign.manually" : "ஒதுக்கு",//no i18n
	"crm.globalsearch.option.all" : "அனைத்தும்",//no i18n
	"webform.status.Active" : "செயலில் உள்ளவை",//no i18n
	"Inactive" : "செயலில் இல்லாதவை",//no i18n
	"Confirmed" : "உறுதியானவை", //no i18n
	"DeletedUser" : "நீக்கப்பட்டவை",//no i18n
	"crm.user.component.unconfirmed": "உறுதியாகாதவை",//no i18n
	"crm.feed.group.admin" : "நிர்வாகி",//no i18n
	"crm.ln.lable.current" : "நடப்பு",//no i18n
	"crm.security.group.users.empty" : "பயனர்கள் இல்லை",//no i18n
	"crm.label.picklist.none" : "ஏதுமில்லை",//no i18n
	"crm.label.selected" : "தேர்ந்தெடுக்கப்பட்டது",//no i18n
	"crm.label.notSelected" : "தேர்ந்தெடுக்கப்படாதது",//no i18n
	"AM" : "AM", /*மு.ப*///no i18n
	"Call" : "அழை",//no i18n
	"crm.phoneNo.Link.Title" : "ஸ்கைப் மூலமாக அழை",//no i18n
	"crm.button.cancel" : "இரத்து செய்",//no i18n
	"crm.button.save" : "சேமி",//no i18n
	"crm.no.data.found" : "தகவல்கள் இல்லை",//no i18n
	"crm.usrpop.non.selected" : "தேர்வு செய்யப்பட்ட பயனர்கள்",//no i18n
	"crm.zti.label.user" : "பயனர் பெயர்",//no i18n
	"crm.auditlog.user" : "பயனர்",//no i18n
	"cob.role" : "பங்கு", //no i18n
	"zoho.email" : "மின்னஞ்சல்",//no i18n
	"Profile" : "சுயவிவரம்",//no i18n
	"crm.label.no.options.found" :  "விருப்பங்கள் ஏதுமில்லை",//no i18n
	"crm.globalsearch.search.title" : "தேடு",//no i18n
	"None" : "ஏதுமில்லை",//no i18n
	"crm.label.criteria.pattern" : "வரன்முறை அமைப்பு", // need to check//no i18n
	"crm.label.edit.criteria.pattern" : "அமைப்பினைத் திருத்து", // need to check//no i18n
	"criteria.error.alert.brackets.count.mismatch" : "அமைப்பு அடைப்புக் குறிகள் பொருந்தவில்லை", // need to check//no i18n
	"criteria.error.alert.brackets.invalid" : "நிபந்தனை மாறிகளைச் சுற்றியுள்ள அடைப்புக் குறிகள் தவறானவை",//no i18n
	"crm.criteria.number.notmatch.check" : "{0}ல் அமைப்பினைச் சரிபார்", // need to check//no i18n
	"criteria.error.alert.other.params" : "உள்ளடக்கம் இந்த அமைப்பில் இல்லை", // need to check//no i18n
	"crm.label.search.for.users" : "பயனர்களைத் தேடு",//no i18n
	"criteria.error.alert.andor.rowcount.mismatch"  : "நீங்கள் தேர்ந்தெடுத்துள்ள நிபந்தனைகளோடு வரன்முறை அமைப்புகள் பொருந்தவில்லை", // need to check//no i18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "நீங்கள் தேர்ந்தெடுத்துள்ள நிபந்தனைகளோடு வரன்முறை அமைப்புகள் பொருந்தவில்லை", // need to check//no i18n
	"and" : "மற்றும்", //No I18n
	"or" : "அல்லது", //No I18n
	"crm.label.or" : "அல்லது", //No I18n
	"crm.label.and" : "மற்றும்", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "{0}வது வரிசையில் புலத்திற்கான சரியான விவரத்தனை உள்ளிடவும்",//no i18n
	"crm.criteria.condition.valid.check" : "{0}ற்கான சரியான நிபந்தனையை உள்ளிடவும்",//no i18n
	"crm.field.valid.check" : "சரியான {0}ஐ உள்ளிடவும்",//no i18n
	"crm.custom.field.less.than.to" : "<i>ஆரம்ப</i> மதிப்பு <i>இறுதி</i> மதிப்பினை விட பெரியதாக இருத்தல் கூடாது",//no i18n
	"crm.alert.label.savepattern" : "வரன்முறையை மாற்றுவதற்கு முன் அமைப்பினைச் சேமி", // need to check//no i18n
	"crm.criteria.max.rowcnt.exceeds" : "உங்களால் புதிய வரன்முறைகளைச் சேர்க்க இயலாது", // need to check//no i18n
	"is" : "இதுவானது",//no i18n
	"isn\'t" : "இதுவல்லாதது",//no i18n
	"contains" : "இதைக் கொண்டிருப்பது",//no i18n
	"doesn\'t contain" : "இது இல்லாதிருப்பது",//no i18n
	"starts with" : "இதில் தொடங்குவது",//no i18n
	"ends with" : "இதில் முடிவது",//no i18n
	"is empty" : "மதிப்பு இல்லாதவை",//no i18n
	"is not empty" : "மதிப்பு இருப்பவை",//no i18n
	"is before" : "இதற்கு முன்",//no i18n
	"is after" : "இதற்குப் பின்",//no i18n
	"between" : "இடைப்பட்டது",//no i18n
	"not between" : "இடையில் இல்லாதது",//no i18n
	"Today" : "இன்று",//no i18n
	"Tommorow" : "நாளை",//no i18n
	"Tommorow Onwards" : "நாளையிலிருந்து தொடங்குகிறது",//no i18n
	"Yesterday" : "நேற்று",//no i18n
	"Till Yesterday" : "நேற்று வரை",//no i18n
	"Last Month" : "கடந்த மாதம்",//no i18n
	"Current Month" : "இந்த மாதம்",//no i18n
	"Next Month" : "அடுத்த மாதம்",//no i18n
	"Last Week" : "கடந்த வாரம்",//no i18n
	"Current Week" : "இந்த வாரம்",//no i18n
	"Next Week" : "அடுத்த வாரம்",//no i18n
	"Age in Days" : "வயது நாட்களில்",//no i18n
	"Due in Days" : "நிலுவை நாட்களில்",//no i18n
	"Scheduled" : "திட்டமிடப்பட்டவை",//no i18n
	"Attended Dialled" : "அழைக்கப்பட்டு பங்கேற்றவர்கள்",//no i18n
	"Unattended Dialled" : "அமைக்கப்பட்டு பங்கேற்காதவர்கள்", //no i18n
	"Overdue" : "கடந்து போன தவணை",//no i18n
	"Cancelled" : "இரத்து செய்யப்பட்டவை",//no i18n
	"Received" : "பெறப்பட்டவை",//no i18n
	"Missed" : "தவற விடப்பட்டவை",//no i18n
	"crm.alert.character.not.allowed" : "{0}ற்கு அனுமதி இல்லை",//no i18n
	"crm.condition.in.last" : "கடைசியில்",//no i18n
	"crm.zinvoice.dueIn" : "அடுத்த தவணை", // need to check//no i18n
	"on" : "இதில்", /*இந்த தேதி*///no i18n
	"before" : "இதற்கு முன்",//no i18n
	"crm.thisweek" : "இந்த வாரம்",//no i18n
	"crm.label.this.month" : "இந்த மாதம்",//no i18n
	"crm.thisyear" : "இந்த வருடம்",//no i18n
	"crm.source.user.and.system" : "பயனர் மற்றும் அமைப்பு",//no i18n
	"crm.source.user.or.system" : "பயனர் அல்லது அமைப்பு",//no i18n
	"crm.label.system2" : "அமைப்பு",//no i18n
	"crm.source.user.only" : "பயனரால் செய்யப்பட்டது", // need to check//no i18n
	"crm.source.system.only" : "அமைப்பினால் செய்யப்பட்டது", // need to check//no i18n
	"crm.condition.till.today" : "இன்று வரை",//no i18n
	"game.month.left" : "ஒரு மாதம்",//no i18n
	"game.months.left" : "{0} மாதங்கள்",//no i18n
	"crm.condition.last.30.days" : "கடைசி 30 நாட்களில்",//no i18n
	"crm.condition.last.60.days" : "கடைசி 60 நாட்களில்",//no i18n
	"crm.condition.last.90.days" : "கடைசி 90 நாட்களில்",//no i18n
	"crm.label.filter.typehere" : "இங்கே உள்ளிடுக",//no i18n
	"crm.filter.is.not" : "இதுவல்லாதது",//no i18n
	"crm.condition.until.now" : "இப்பொழுது வரை",//no i18n
	"crm.filter.email.isblocked" : "முடக்கப்பட்டுள்ளவை",//no i18n
	"crm.filter.email.isnotblocked" : "முடக்கப்படாதவை",//no i18n
	"crm.label.no.results.match" : "முடிவுகள் பொருந்தவில்லை",//no i18n
	"crm.label.select.user" : "பயனர்களை தேர்ந்தெடுக்க சொடுக்கவும்.",//no i18n
	"current.logged.in.user" : "உள் நுழைந்த பயனர்",//no i18n
	"current.logged.in.user.definition" : "பதிவுகளின் செயல்பாடுகளைத் துவங்கி வைக்கும் பயனர்",//no i18n
	"crm.security.group" : "குழு",//no i18n
	"crm.security.role" : "பங்கு",//no i18n
	"Date" : "தேதி",//no i18n
	"crm.field.valid.decimal.check2" : "<i>{0}</i> புலத்திற்கான தசம இடங்கள் {1}க்குக் குறைவாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்",//no i18n
	"crm.field.empty.check" : "{0} வெறுமையாக இருக்கக் கூடாது",//no i18n

	//filter related keys-start
	"crm.lead.prediction.convert.high" : "அதிகம்",//no i18n
	"crm.inv.mail.time.two.days.subject" : "{0} - {1}",//no i18n
	"crm.lead.prediction.convert.medium" : "நடுத்தரம்",//no i18n
	"crm.lead.prediction.convert.low" : "குறைவு",//no i18n
	"crm.lead.prediction.tooltip.convert" : "ஒற்றுமை",//no i18n
	"crm.lead.prediction.tooltip.score" : "புள்ளிகள்",//no i18n
	"Planned" : "திட்டமிடப்பட்டவை",//no i18n
	"Invited" : "அழைக்கப்பட்டவை",//no i18n
	"Sent" : "அனுப்பப்பட்டவை",//no i18n
	"Received" : "பெறப்பட்டவை",//no i18n
	"Opened" : "திறக்கப்பட்டவை",//no i18n
	"Responded" : "பதிலளிக்கப்பட்டவை",//no i18n
	"Bounced" : "திரும்ப வந்தவை",//no i18n
	"Opted\ Out": "தேர்ந்தெடுக்கப்பட்டவை",//no i18n
	"crm.filter.label.with.open" : "திறந்த நிலையில் {0}",//no i18n
	"crm.filter.label.without.open" : "திறக்கப்படாத நிலையில் {0}",//no i18n
	"crm.filter.label.without.any" : "எந்த நிலையிலும் இல்லாமல் {0}",//no i18n
	"crm.filter.label.with.module" : "{0} உடன்",//no i18n
	"Overdue" : "கடந்து போன தவணை",//no i18n
	"crm.filter.label.activity.due" : "{0} தவணை", //no i18n
	"crm.filter.label.activity.done" : "முடிந்தவை",//no i18n
	"Notes" : "குறிப்புகள்",//no i18n
	"crm.filter.label.notes.added" : "குறிப்புகள் சேர்க்கப்பட்டது",//no i18n
	"crm.filter.label.sent" : "அனுப்பப்பட்டவை",//no i18n
	"crm.filter.label.not.sent": "அனுப்பப்படாதவை",//no i18n
	"crm.filter.label.opened" : "திறக்கப்பட்டவை",//no i18n
	"crm.filter.label.not.opened" : "திறக்கப்படாதவை",//no i18n
	"crm.filter.label.received" : "பெறப்பட்டவை",//no i18n
	"crm.filter.label.not.received" : "பெறப்படாதவை",//no i18n
	"crm.filter.label.bounced" : "திரும்ப வந்தவை",//no i18n
	"crm.filter.label.opened.not.replied" : "திறக்கப்பட்டு பதிலளிக்கப்படாதவை", //no i18n
	"crm.filter.label.any" : "மேலே உள்ள ஏதேனும்",//no i18n
	"crm.zia.config.potential.amount": "{0} தொகை",//no i18n
	"Quote\ Stage" : "{0} நிலை",//no i18n
	"crm.module.owner" : "{0} உரிமையாளர்",//no i18n
	"Potential\ Closing\ Date" : "{0} முடிவுறும் தேதி",//no i18n
	"crm.lead.prediction.likely.convert" : "மாறுவதற்கு வாய்ப்புள்ளவை",//no i18n
	"crm.predictions.feature.label" : "கணிப்பு",//no i18n
	"crm.intelligence.prediction.likelywin" : "வெற்றி பெற வாய்ப்புள்ளவை",//no i18n
	"crm.intelligence.prediction.likelylose" : "தோல்வியுற வாய்ப்புள்ளவை",//no i18n
	"crm.intelligence.prediction.halfchance" : "50:50 வாய்ப்பு",//no i18n
	"crm.intelligence.prediction.score" : "கணிக்கப்பட்ட மதிப்பு",//no i18n
	"crm.lead.prediction.recent.score" : "சமீபத்தில் கணிக்கப்பட்ட மதிப்பு",//no i18n
	"crm.intelligence.prediction.lastconv" : "கடைசி மூன்று உரையாடல்கள்",//no i18n
	"crm.intelligence.prediction.recordsfocus" : "கவனம் செலுத்தப்படக்கூடிய பதிவுகள்",//no i18n
	"crm.intelligence.prediction.slowmoving" : "மெதுவாக நகர்பவை", // context//no i18n
	"crm.intelligence.prediction.trend.down" : "சமீபத்தில் பிரபலமாக இருந்து போனவை",//no i18n
	"crm.label.touched.records" : "மாற்றப்பட்ட பதிவுகள்",//no i18n
	"crm.label.untouched.records" : "மாற்றப்படாத பதிவுகள்",//no i18n
	"crm.label.record.action" : "பதிவு செயல்பாடுகள்",//no i18n
	"workflow.rule.view.label.Modified" : "மாற்றப்பட்டவை",//no i18n
	"crm.label.not.modified" : "மாற்றப்படாதவை",//no i18n
	"crm.label.related.records.action" : "தொடர்புடைய பதிவுகளின் செயல்பாடு",//no i18n
	"Done" : "முடிந்தவை",//no i18n
	"crm.label.not.done" : "முடிக்கப்படாதவை",//no i18n
	"sentiment.model" : "மின்னஞ்சல் மனநிலை",//no i18n
	"sentiment.criteria.count" : "எண்ணிக்கை",//no i18n
	"sentiment.criteria.percentage" : "விழுக்காடு",//no i18n
	"sentiment.criteria.lastmail" : "கடைசி மின்னஞ்சலுக்காக",//no i18n
	"Chats" : "அரட்டைகள்",//no i18n
	"Attended" : "பங்கேற்றவர்கள்",//no i18n
	"crm.lead.prediction.popup.text" : "மாற்றப்படுவதற்கான வாய்ப்பு உள்ளது {0} ஆக இருக்கும் போது கணிக்கப்பட்ட மதிப்பு {1}ற்கு இடையில் இருக்க வேண்டும்",//no i18n
	"crm.lead.prediction.popup.final" : "வடிகட்டியை மாற்றி மீண்டும் முயற்சிக்கவும்",//no i18n
	"crm.custom.field.less.than.to1" : "தொடக்க மதிப்பு இறுதி மதிப்பினை விட அதிகமாக இருக்கக் கூடாது",//no i18n
	"Last\ Activity\ Date" : "கடைசி செயல்பாட்டு நேரம்",//no i18n
	"crm.label.vendor.name" : "{0} பெயர்",//no i18n
	"days" : "நாட்கள்",//no i18n
	"weeks" : "வாரங்கள்",//no i18n
	"months" : "மாதங்கள்",//no i18n
	"crm.label.general.small.after" : "இதற்குப் பின்",//no i18n
	"Last\ Week" : "கடந்த வாரம்",//no i18n
	"Last\ Month" : "கடந்த மாதம்",//no i18n
	"crm.module.name" : "{0} பெயர்",//no i18n
	"Campaign" : "பிரச்சாரம்",//no i18n
	"Tasks" : "பணிகள்",//no i18n
	"Calls" : "அழைப்புகள்",//no i18n
	"Events" : "நிகழ்வுகள்",//no i18n
	"sentiment.criteria.wrongcriteria" : "வரன்முறை மதிப்பு {0}ஐ விட அதிகமாக இருக்கக் கூடாது",//no i18n
	"crm.chosen.minimum.input.text" : "தயவுசெய்து {0} அல்லது அதற்கு அதிகமான எழுத்துகளை உள்ளிடுக",//no i18n
	"crm.intelligence.prediction.trendup" : "பிரபலமாகிக் கொண்டிருப்பது", //no i18n
	"crm.intelligence.prediction.trenddown" : "பிரபலமாக இருந்தது",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"முடிவுற்றவை" ,//no i18n
	"crm.label.success":"சரியானவை" ,//no i18n
	"crm.label.Failure":"தவறானவை" ,//no i18n
	"Both":"இரண்டும்" ,//no i18n
	"crm.condition.cannot.empty":"நிபந்தனைகள் காலியாக இருக்க முடியாது.",//no i18n
	"crm.sentiment.Positive" : "நேர்மறை",//no i18n
	"crm.sentiment.Negative" : "எதிர்மறை",//no i18n
	"sentiment.positiveandnegative" : "நேர்மறை மற்றும் எதிர்மறை",//no i18n
	"sentiment.positiveornegative" : "நேர்மறை அல்லது எதிர்மறை",//no i18n
	"sentiment.positiveonly" : "நேர்மறை மட்டும்",//no i18n
	"sentiment.negativeonly" : "எதிர்மறை மட்டும்",//no i18n
	"crm.sentiment.Neutral" : "நடுநிலை",//no i18n
	"crm.filters.select.campaign.type" : "{0} வகையைத் தேர்ந்தெடு",//no i18n
	"crm.filters.select.campaign.status" : "{0} நிலையைத் தேர்ந்தெடு",//no i18n
	"campaign.Member" : "Member",//no i18n
	"Service":"Service",//no i18n
	"Activities" : "செயல்பாடுகள்",//no i18n
	"crm.livedesk.pot.nextdays" : "அடுத்த {0} நாட்கள்",//no i18n
	"Today\ +\ Overdue" : "இன்று + கடந்து போன தவணை",//no i18n
	"User" : "பயனர்",//no i18n
	"Attended\ Dialled" : "அழைக்கப்பட்டு பங்கேற்றவர்கள்",//no i18n
	"Unattended\ Dialled" : "அமைக்கப்பட்டு பங்கேற்காதவர்கள்",//no i18n
	"condition.till.now" : "இப்பொழுது வரை",//no i18n
	"crm.recurring.no.months": "{0} மாதங்கள்",//no i18n
	"crm.lead.prediction.tooltip" : "மாறுவதற்கு வாய்ப்புள்ளவை - மதிப்பு வரம்பு",//no i18n
	"crm.website.activity" : "இணையதள செயல்பாடுகள்",//no i18n
	"crm.label.More" : "மேலும் அதிகமாக",//no i18n
	"crm.label.By" : "இதனால்",//no i18n
	"crm.chosen.searching.text" : "தேடிக்கொண்டிருக்கிறது...",//no i18n
	"crm.label.memberstatus.is" : "மற்றும் உறுப்பினரின் நிலை",//no i18n
	"crm.events.duration" : "கால இடைவெளி",//no i18n
	"crm.title.clear.name" : "அழி",//no i18n
	"crm.label.status.is" : "மற்றும் நிலை",//no i18n
	"zia.last3.help" : "அழைப்புகள், பணிகள், {0}, பெறப்பட்ட மின்னஞ்சல்கள், குறிப்புகள், பார்வையிடல்கள், சமூக பின்னூட்டங்கள்,  டெஸ்கிலிருந்து பெறப்பட்ட ஆதரவு கோரிக்கைகள் போன்ற உரையாடல்கள்.",//no i18n
	"crm.label.tag.related.to" : "இதனுடன் தொடர்புடையது",//no i18n
	"crm.alert.maximum.text.values.contains" : "இந்தப் புலத்தில் {0}ற்கும் மேற்பட்ட எழுத்துக்களை உள்ளிட இயலாது",//no i18n
	//filter related keys-end//no i18n

	//smart filter keys
	"crm.lead.prediction.focus.nofollowup" : "தொடரில் அடுத்ததாக ஏதும் இல்லை",//no i18n
	"Failed" : "தோல்வியுற்றவை", //no i18n
	"Clicked" : "சொடுக்கப்பட்டவை",//no i18n
	"Marked.as.spam" : "பயனற்றதாகக் குறிக்கப்பட்டவை",//no i18n
	"Member\ Status" : "உறுப்பினரின் நிலை",//no i18n
	"Camp\ Member\ Status" : "பிரச்சார உறுப்பினரின் நிலை",//no i18n
	"Campaign\ Member" : "பிரச்சார உறுப்பினர்",//no i18n
	"crm.zinvoice.dueby" : "தவணைக்குரியவர்",//no i18n
	"Rollbacked" : "பழைய நிலைக்கு மாற்றப்பட்டவை",//no i18n
	"crm.label.filter.email.status" : "சமீபத்தைய மின்னஞ்சல் நிலை",//no i18n
	"crm.label.filter.email.clicked" : "சொடுக்கப்பட்டது",//no i18n
	"crm.label.filter.email.info" : "அனுப்பப்பட்ட அல்லது பெறப்பட்ட மின்னஞ்சல்களின் சமீபத்தைய நிலை கொண்டு பதிவுகளை வடிகட்டு",//no i18n
	"crm.label.Potential\ Closing\ Date" : "முடிவதற்கு சாத்தியமான தேதி",//no i18n
	"crm.warning.select.filter" : "வடிகட்டுவதற்காக குறைந்தபட்சம் ஒரு புலத்தினையாவது தேர்வு செய்க",//no i18n
	"Contact\ Name" : "{0} பெயர்",//no i18n
	"Advertisement" : "விளம்பரம்",//no i18n
	"Banner\ Ads" : "பதாகை விளம்பரங்கள்",//no i18n
	"Conference" : "மாநாடு",//no i18n
	"Direct\ mail" : "நேரடி அஞ்சல்",//no i18n
	"crm.taskreminder.line8" : "மின்னஞ்சல்",//no i18n
	"Others" : "மற்றவை", //no i18n
	"Partner" : "கூட்டாளர்",//no i18n
	"Public\ Relations" : "மக்கள் தொடர்புகள்",//no i18n
	"Referral\ Program" : "பரிந்துரை திட்டம்",//no i18n
	"Telemarketing" : "தொலை சந்தைப்படுத்துதல்",//no i18n
	"Trade\ Show" : "வர்த்தக நிகழ்ச்சி",//no i18n
	"Webinar" : "வலைத்தள கருத்தரங்கு",//no i18n
	"Active" : "செயலில் உள்ளவை",//no i18n
	"Complete" : "முடிவுற்றவை",//no i18n
	"Inactive" : "செயலில் இல்லாதவை",//no i18n
	"Planning" : "திட்டமிடல்",//no i18n
	"Tomorrow" : "நாளை",//no i18n
	"crm.label.add.note" : "குறிப்பினைச் சேர்",//no i18n
	"crm.label.simply.by" : "செய்தவர்", // context ? //note added "by"//no i18n
	"crm.general.addnote" : "ஒரு குறிப்பினைச் சேர்",//no i18n
	"crm.general.addtitle" : "ஒரு தலைப்பைச் சேர்",//no i18n
	"crm.label.attach.file" : "கோப்பினை இணை",//no i18n
	"crm.button.mass.delete" : "நீக்கு",//no i18n
	"crm.warning.delete.record" : '"{0}" ஐ நீக்க வேண்டுமென உறுதியாக இருக்கிறீர்களா ?',//no i18n
	"crm.label.yes" : "ஆம்",//no i18n
	"crm.note.view.previous" : "முந்தையக் குறிப்புகளைப் பார்வையிடு",//no i18n
	"of" : "இன்",//no i18n
	"crm.label.notes" : "குறிப்புகள்",//no i18n
	"crm.note.recent.first" : "சமீபத்தையது முதலில்",//no i18n
	"crm.note.recent.last" : "சமீபத்தையது இறுதியாக",//no i18n
	"crm.territory.label.only" : "{0} மட்டும்",//no i18n
	"crm.button.apply.filter" : "வடிகட்டியை செயல்படுத்து",//no i18n
	"PM" : "PM", /*பி.ப*/ //No I18n
	"crm.mb.newversion.msg4":"சரி. விளங்கிவிட்டது!", //no i18n
	"Jan": "ஜன.",//no i18n
    "Feb": "பிப்.",//no i18n
    "Mar": "மார்.",//no i18n
    "Apr": "ஏப்.",//no i18n
    "short.may": "மே",//no i18n
    "Jun": "ஜூன்",//no i18n
    "Jul": "ஜூலை",//no i18n
    "Aug": "ஆக.",//no i18n
    "Sep": "செப்.",//no i18n
    "Oct": "அக்.",//no i18n
    "Nov": "நவ.",//no i18n
    "Dec": "டிச.",//no i18n
    "crm.label.unmapped.stages" : "கணக்கிடப்படாதது",//no i18n
    "crm.wf.summary.label.ShowInstantActions" : "அதிகமாக காண்பி",//no i18n
    "crm.wf.summary.label.HideInstantActions" : "குறைவாக காண்பி",//no i18n
    "crm.label.account.created" : "இப்பொழுது {0} ஆனது உருவாக்கப்படும்",//no i18n
    "crm.krp.no.records.found" : "{0} இல்லை",//no i18n
    "crm.module.new" : "புதிய {0}",//no i18n
    "crm.label.view" : "பார்வையிடு",//no i18n
    "crm.nsocial.customers" : "வாடிக்கையாளர்கள்",//no i18n
    "crm.nsocial.open.potential" : "{0}ஐத் திற",//no i18n
    "crm.nsocial.lead.contact" : "{0}/{1}",//no i18n
    "crm.field.length.check" : "{0} மதிப்பு அனுமதிக்கப்பட்ட நீளத்தை விட அதிகமாக உள்ளது",//no i18n
    "crm.lower.now" : "இப்பொழுது",//no i18n
    "crm.time.min.ago" : "{0} நிமிடங்களுக்கு முன்",//no i18n
    "crm.time.mins.ago" : "{0} நிமிடங்களுக்கு முன்",//no i18n
    "crm.time.hr.ago" : "{0} மணிகளுக்கு முன்",//no i18n
    "crm.time.hrs.ago" : "{0} மணிகளுக்கு முன்",//no i18n
    "AllUsers" : "அனைத்து பயனர்களும்",//no i18n
    "crm.label.search" : "தேடு",//no i18n
    "crm.api.filterby" : "இதனைக் கொண்டு வடிகட்டு",//no i18n
    "crm.customview.nofields.found" : "பொருந்தக்கூடிய புலங்கள் ஏதுமில்லை",//no i18n
    "crm.setup.system.ziarecommendation" : "பரிந்துரை",//no i18n
    "crm.filter.label.all.products" : "எல்லா {0}",//no i18n
    "crm.filter.label.select.products" : "{0} தேர்ந்தெடுக்கப்பட்டது",//no i18n
    "crm.reviewprocess.smart.filter" : "மறு ஆய்வு செய்தல் பதிவு நிலை",//no i18n
    "crm.dashboard.sharing.empty.value.alert" : "ஏதேனும் ஒரு மதிப்பினை தேர்வு செய்யவும்",//no i18n
    "crm.recommendation.empty.value.alert" : "ஏதேனும் ஒரு பரிந்துரையைத் தேர்வு செய்யவும்",//no i18n
    "allTime" : "எல்லா நேரமும்",//no i18n
    "crm.segmentation.segment.score" : "பிரிவுப் புள்ளிகள்",//no i18n
    "crm.filter.label.in" : "இதன் அடிப்படையில்",//no i18n
    "crm.filter.label.and.purchase.in" : "வாங்கும் நிலையில் உள்ளதாக எதிர்பார்க்கப்படுபவர்கள்",//no i18n
    "crm.filter.label.last.purchased" : "சமீபத்தில் வாங்கியது",//no i18n
    "crm.filter.label.a.day" : "ஒரு நாள்",//no i18n
    "crm.filter.label.a.week" : "ஒரு வாரம்",//no i18n
    "crm.filter.label.a.month" : "ஒரு மாதம்",//no i18n
    "crm.cal.custom" : "தனிப்பயனாக்கம்",//no i18n
    "crm.mb.field.common.empt" : "மதிப்பு காலியாக இருக்க முடியாது",//no i18n
    "crm.chosen.error.loading.text" : "உங்கள் முடிவுகளை எங்களால் காண்பிக்க இயலவில்லை",//no i18n
    "crm.filter.label.firstbuy" : "முதல் முறை",//no i18n
    "crm.filter.label.cwbab" : "சார்பு",//no i18n
    "crm.filter.label.fbt" : "தொகுப்பு",//no i18n
    "crm.filter.label.rebuy" : "மீண்டும்",//no i18n
    "crm.filter.label.nextbuy" : "வரிசை",//no i18n
    "crm.mxnlookup.select" : "{0}ஐ ஒதுக்கு",//no i18n
    "crm.lookup.chooserecord" : "{0}ஐத் தேர்ந்தெடு",//no i18n
    "crm.record.selected" : "{0} தேர்ந்தெடுக்கப்பட்டது",//no i18n
	"crm.module.empty.message" : "{0} இல்லை",//no i18n
	"crm.mxnlookup.selected" : "{0} ஒதுக்கப்பட்டது",//no i18n
	"crm.security.error" : "இந்த செயல்பாட்டினைச் செய்வதற்கான போதுமான அனுமதி உங்களுக்கு இல்லை. உங்கள் நிர்வாகியைத் தொடர்பு கொள்ளவும்",//no i18n
	"crm.label.creator.noPermission" : "அனுமதி மறுக்கப்பட்டது",//no i18n
	"crm.segmentation.recency" : "தற்காலிகமானவை",//no i18n
	"crm.segmentation.frequency" : "கால இடைவெளி",//no i18n
	"crm.segmentation.monetary" : "நிதி",//no i18n
	"crm.smartfilter.related.module.msg" : "மூன்று தொகுதிகளுக்கு மேல் தேர்ந்தெடுக்க முடியாது",//no i18n
	"crm.smartfilter.related.module.msg1" : "(உதாரணம் : மின்னஞ்சல், செயல்பாடுகள், குறிப்புகள்)",//no i18n
	"crm.smartfilter.related.module.msg2" : "கால இடைவெளி காலியாக இருக்கக் கூடாது",//no i18n
	"crm.label.timeZone" : "நேர மண்டலம்",//no i18n
	"crm.label.insufficient.privileges" : "இந்த செயல்பாட்டினைச் செய்வதற்குப் போதுமான சலுகைகள் இல்லை. உங்கள் நிர்வாகியைத் தொடர்பு கொள்ளுங்கள்",//no i18n
	"crm.select" : "தேர்வு செய்",//no i18n
	"crm.filter.header.secton.system" : "அமைப்பு வரையறுத்த வடிகட்டிகள்",//no i18n
	"crm.filter.header.secton.fields" : "புலங்களைக் கொண்டு வடிகட்டு",//no i18n

	"crm.createfield.calcinfo.new" : "இந்த புலம் உங்களது உள்ளீடுகளுக்கு ஒரு கால்குலேட்டர் போன்று செயல்படும்.</br> <b>எடுத்துக்காட்டாக 20+20</b> என்பது தானாகவே <b>40</b> ஆக எடுத்துக் கொள்ளப்படும்",//no i18n
	"crm.lable.read.only" : "படிப்பதற்கு மட்டுமேயான புலம்",//no i18n
	"crm.column.sort.asc" : "ஏறு வரிசை",//no i18n
	"crm.column.sort.desc" : "இறங்கு வரிசை",//no i18n
	"crm.column.unsort" : "வரிசைப்படுத்தப்படாதது",//no i18n
	"custmr.prtl.notes.shr.to.custmr" : "பயனாளருக்குப் பகிர்",//no i18n
	"crm.label.edited" : "திருத்தப்பட்டது",//no i18n
	"crm.label.edited.on" : "இதில் திருத்தப்பட்டது", // need to check//no i18n
	"crm.message.limit.exceed" : "{1}ற்க்கு {0} எழுத்துகள் மட்டுமே அனுமதிக்கப்பட்டுள்ளது",//no i18n
	"custmr.prtl.notes.shrd.with.custmr" : "பயனாளருடன் பகிர்",//no i18n
	"crm.button.ok" : "சரி",//no i18n
	"crm.role.already.selected" : "இந்த உறுப்பு ஏற்கனவே தேர்ந்தெடுக்கப்பட்டுள்ளது",//no i18n
	"crm.user.deleted" : "பயனர் நீக்கப்பட்டார்",//no i18n
	"crm.account.closed" : "இந்தக் கணக்கு மூடப்பட்டது",//no i18n
	"crm.start.chat" : "அரட்டையைத் தொடங்கு",//no i18n
	"crm.start.call" : "அழைப்பினைத் தொடங்கு",//no i18n
	"crm.start.video.call" : "வீடியோ அழைப்பினைத் தொடங்கு",//no i18n
	"crm.label.scoring.rules" : "மதிப்பீட்டு விதிகள்",//no i18n
	"Score" : "புள்ளிகள்",//no i18n
	"Positive Score" : "நேர்மறைப் புள்ளிகள்",//no i18n
	"Negative Score" : "எதிர்மறைப் புள்ளிகள்",//no i18n
	"Touch Point Score":"தொடு புள்ளிகள்", // need to check//no i18n
	"Positive Touch Point Score":"நேர்மறை தொடு புள்ளிகள்", // need to check//no i18n
	"Negative Touch Point Score":"எதிர்மறை தொடு புள்ளிகள்", // need to check//no i18n
	"crm.label.type.minutes" : "நிமிடங்களில் உள்ளிடவும்",//no i18n
//no i18n
	"is OPEN" : "திறந்த நிலையில் உள்ளவை", // context //stage of a work "is open"//no i18n
	"is WON" : "வெற்றி பெற்றவை", // context //stage of a work "is won"//no i18n
	"is LOST" : "தோல்வியுற்றவை", // context //stage of a work "is lost"//no i18n
	"crm.potential.all.open" : "திறக்கப்பட்ட நிலைகள்",//no i18n
	"crm.potential.all.won" : "வென்று மூடப்பட்ட நிலைகள்",//no i18n
	"crm.potential.all.lost" : "தோல்வியுற்று மூடப்பட்ட நிலைகள்",//no i18n
	"crm.fileuploader.message.responseerror" : "பதிவேற்றம் தோல்வியுற்றது",//no i18n
	"crm.storage.create.error" : "நீங்கள் அதிகபட்ச சேமிப்பு அளவினைத் தாண்டிவிட்டதால் புதிதாக பதிவுகளை உருவாக்க இயலாது",//no i18n
	"crm.storage.create.error.client" : "தங்களது நிர்வாகி அதிகபட்ச சேமிப்பு அளவினை அடைந்து விட்டதால் புதிய பதிவுகளை உருவாக்க இயலாது. இந்த பிரச்சனையைத் தீர்க்க {0}ஐத் தொடர்பு கொள்ளவும்",//no i18n
	"crm.storage.avail.info" : "({1}ல் {0} மீதமுள்ளது)",//no i18n
	"crm.storage.error.key.manage" : "உங்கள் தகவல் சேமிப்பகத்தை நிர்வகிக்கவும்",//no i18n
	"Records" : "பதிவுகள்",//no i18n
	"crm.related.contact.account" : "{0} related to {1}",//No I18n
	"crm.allcontact.show" : "All {0}",//No I18n
	"crm.button.mass.show" : "Show",//No I18n
	"crm.msg.custom.view.not.replied" : "Not Replied Messages", //NO I18N
	"crm.msg.custom.view.replied" : "Replied Messages",//NO I18N
	"crm.workflow.alert.type.otherEmails" : "கூடுதலான மின்னஞ்சல் முகவரிகளை வேறுபடுத்த கமா குறியைப் பயன்படுத்தவும்" , //NO I18N
	"crm.workflow.alert.additional.recipients" : "கூடுதலான பெறுநர்கள்", //NO I18N
	"crm.recipient.add.recipient" : "கூடுதலான பெறுநர்களைச் சேர்" , //NO I18N
	"crm.recipient.invalid.email" : "மின்னஞ்சல்கள் தவறாக உள்ளன ", //NO I18N
	"crm.workflow.select.recipients" : "பெறுநர்கள்", //NO I18N
	"crm.campaign.member.status" : "Member Status",//no i18n
	"crm.dashboard.select.type" : "Select {0}",//no i18n
	"crm.campaign.service.status":"Service Status",//no i18n
	"crm.custom.module.no.profile.selected.alert1":"Please select at least one profile.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Sorry, but you can't remove a default profile.",//NO I18N
	"crm.inv.label.add.emails" : "மின்னஞ்சல்களை உள்ளிடவும்", //NO I18N
	"crm.prediction.analytics.filter.year":"Last Year",//no i18n
	"Previous\ FY":"Previous FY",//no i18n
	"Current\ FY":"Current FY",//no i18n
	"Next\ FY":"Next FY",//no i18n
	"Current\ FQ":"Current FQ",//no i18n
	"Next\ FQ":"Next FQ",//no i18n
	"Previous\ FQ":"Previous FQ",//no i18n
	"crm.label.next.year":"Next Year",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{1}-ஐ விட {0} அதிகமாக அல்லது சமமாக இருக்க வேண்டும்.",
	"crux.users.selected.plural" : "{0} பயனர்கள் தேர்ந்தெடுக்கப்பட்டனர்.",
	"crux.user.selected.singular" :"{0} பயனர் தேர்ந்தெடுக்கப்பட்டார்.",
	"crux.criteria.empty.secondaryfield.module" : "{1} தொகுதியில் பொருந்தக்கூடிய {0} புலம் இல்லை",
	"crux.criteria.empty.secondaryfield" : "ஒப்பிடுவதற்கு வேறு {0} புலம் இல்லை, ஒப்பிட ஒரு மதிப்பை உள்ளிடவும்.",
	"crux.logged.in.role.definition" : "பதிவுச் செயல்களைத் தொடங்கும் பயனரின் பொறுப்பு",
	"crux.max.limit.unselect" : "{0} {1}-க்கு மேல் தேர்வுநீக்க முடியாது.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d ஏற்கனவே தேர்ந்தெடுக்கப்பட்டது" //No I18N
}
